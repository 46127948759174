import { useFlatfile, Sheet, Space, Workbook, useEvent, useListener } from '@flatfile/react'
import { Button, notification } from 'antd'
import api from '@flatfile/api'
import { useState } from 'react'
import { isNotEmpty } from '../../utils'
import { autocast } from '@flatfile/plugin-autocast'

const FlatFilePortal = ({ sheet, onSubmit }) => {
	const { openPortal, closePortal } = useFlatfile()
	const [fileName, setFileName] = useState(`${sheet.slug}.csv`)
	const [validData, setValidData] = useState([])

	useListener(autocast(sheet.slug))

	useEvent(
		'file:created',
		{},
		async (event) => {
			try {
				const { fileId } = event.context
				const file = await api.files.get(fileId)
				setFileName(file?.data?.name)
			} catch (error) {
				console.error(error)
				notification.error({
					message: 'Error',
					description: `${error.message}`,
					placement: 'bottomRight'
				})
			}
		}
	)

	useEvent(
		'job:outcome-acknowledged',
		{
			operation: `sheetSubmitAction-${sheet.slug}`,
			status: 'complete'
		},
		async () => {
			if (validData.length > 0) {
				setValidData([])
				closePortal()
			}
		}
	)

	const getFormattedData = (records) => {
		const rows = []
		for (const record of records) {
			const values = record.values
			let row = {}
			for (const [key, value] of Object.entries(values)) {
				if (value.valid) {
					row[key] = isNotEmpty(value.value) ? value.value : undefined
				}
			}
			rows.push(row)
		}
		return rows
	}

	return (
		<div>
			<Button
				disabled={false}
				size='large'
				type='primary'
				shape='round'
				onClick={openPortal}
			>
				Get Started
			</Button>
			<Space
				config={{
					namespace: 'nuport-batb-web',
					metadata: {
						sidebarConfig: {
							showSidebar: false
						},
						theme: {
							root: {
								primaryColor: '#278EA5',
								buttonBorderRadius: '0px',
								badgeBorderRadius: '0px',
								checkboxBorderRadius: '0px',
								interactiveBorderRadius: '0px',
								modalBorderRadius: '0px',
								pillBorderRadius: '0px',
								popoverBorderRadius: '0px'
							}
						}
					}
				}}
			>
				<Workbook
					config={{
						namespace: 'nuport-batb-web',
						name: 'Upload CSV',
						labels: ['pinned'],
						settings: {
							trackChanges: true
						}
					}}
				>
					<Sheet
						config={sheet}
						onSubmit={async ({ sheet }) => {
							try {
								const { records } = await sheet.validData()
								const validData = getFormattedData(records)
								setValidData(validData)
								onSubmit?.({ validData, fileName })
							} catch (error) {
								console.error(error)
								notification.error({
									message: 'Error',
									description: `${error.message}`,
									placement: 'bottomRight'
								})
							}
						}}
						onRecordHook={(record) => {
							return record
						}}
					/>
				</Workbook>
			</Space>
		</div>
	)
}

export default FlatFilePortal
