import UploadCsvModal from '../upload-csv-modal'
import { Upload } from '../../services/api/upload'

const UploadPurchaseOrdersModal = ({ visible, onCancel, onComplete }) => {
	return (
		<UploadCsvModal
			title='Upload Purchase Orders'
			type='purchase-orders'
			fields={[
				{ label: 'ID', key: 'id', type: 'string' },
				{ label: 'Location ID', key: 'locationId', type: 'string' },
				{ label: 'Vendor ID', key: 'vendorId', type: 'string' },
				{ label: 'Purchase Date', key: 'purchaseDate', type: 'string' },
				{ label: 'Product ID', key: 'productId', type: 'string' },
				{ label: 'Quantity', key: 'quantity', type: 'number' },
				{ label: 'Package Quantity', key: 'packageQuantity', type: 'number' }
			]}
			firstStepPrompt='Download an example CSV file. Do not modify the first row of the CSV. This CSV will inform you on how to structure your data.'
			secondStepPrompt='Edit the CSV file by adding new rows. Rows will be uploaded as new purchase orders into the platform.'
			visible={visible}
			onCancel={onCancel}
			onComplete={onComplete}
			downloadTask={Upload.downloadPurchaseOrdersCsv}
			uploadTask={Upload.uploadPurchaseOrdersCsv}
		/>
	)
}

export default UploadPurchaseOrdersModal
