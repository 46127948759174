import UploadCsvModal from '../upload-csv-modal'
import { Upload } from '../../services/api/upload'

const UploadOrdersModal = ({ visible, onCancel, onComplete }) => {
	return (
		<UploadCsvModal
			title='Upload Orders'
			type='orders'
			fields={[
				{ label: 'Order Number', key: 'id', type: 'string' },
				{ label: 'From', key: 'from', type: 'string' },
				{ label: 'To', key: 'to', type: 'string' },
				{ label: 'Order Type', key: 'orderType', type: 'string' },
				{ label: 'Delivery Date', key: 'deliveryDate', type: 'string' },
				{ label: 'Material Code', key: 'productId', type: 'string' },
				{ label: 'Outer Quantity', key: 'quantity', type: 'number' },
				{ label: 'Master Case Quantity', key: 'packageQuantity', type: 'number' }
			]}
			firstStepPrompt='Download an example CSV file. Do not modify the first row of the CSV. This CSV will inform you on how to structure your data.'
			secondStepPrompt='Edit the CSV file by adding new rows. Rows will be uploaded as new orders into the platform.'
			visible={visible}
			onCancel={onCancel}
			onComplete={onComplete}
			downloadTask={Upload.downloadOrdersCsv}
			uploadTask={Upload.uploadOrdersCsv}
		/>
	)
}

export default UploadOrdersModal
